<template>
  <el-dialog
    v-loading="loading"
    v-elDragDialog
    width="500px"
    :title="isCreated ? $t('deviceType.newDevice') : $t('deviceType.editDevice')"
    :close-on-click-modal="false"
    :visible.sync="show"
    class="add-deviceType"
  >
    <el-form :model="form" :rules="rules" size="small" ref="form" label-width="100px">
      <el-form-item :label="$t('deviceList.deviceType')" prop="name">
        <el-input v-model.trim="form.name" />
      </el-form-item>
      <el-form-item :label="$t('deviceType.productPange')" prop="series">
        <el-select v-model="form.series" :placeholder="$t('deviceType.selectProductPange')">
          <el-option
            v-for="item in options"
            :key="item.value"
            :label="item.label"
            :value="item.value"
          />
        </el-select>
      </el-form-item>
      <el-form-item :label="$t('deviceType.signFileName')" prop="signatureFileName">
        <el-select v-model="form.signatureFileName" :placeholder="$t('placeholder')">
          <el-option v-for="a in signList" :label="a.name" :value="a.id" :key="a.id" />
        </el-select>
      </el-form-item>

      <el-form-item :label="$t('deviceType.deviceImg')" :prop="isCreated ? 'file' : ''">
        <el-upload
          class="avatar-uploader"
          action="null"
          :show-file-list="false"
          :auto-upload="false"
          :on-change="onchange"
        >
          <img v-if="imageUrl" :src="imageUrl" class="avatar" />
          <i v-else class="el-icon-plus avatar-uploader-icon"></i>
        </el-upload>
      </el-form-item>

      <el-form-item :label="$t('deviceType.remark')">
        <el-input v-model.trim="form.remark" />
      </el-form-item>
    </el-form>
    <span slot="footer" class="dialog-footer">
      <el-button @click="show = false">{{ $t('cancel') }}</el-button>
      <el-button type="primary" @click="submitForm(form)">{{ $t('save') }}</el-button>
    </span>
  </el-dialog>
</template>

<script>
import dialogCommonParams from '@/mixins/dialogCommonParams'
export default {
  mixins: [dialogCommonParams],
  components: {},
  data() {
    return {
      loading: false,
      fileList: [],
      imageUrl: '',
      form: {
        file: '',
        name: '',
        series: '',
        signatureFileName: '',
        remark: ''
      },
      rules: {
        file: [{ required: true, message: this.$t('deviceType.pleaseUploadPicture') }],
        series: [
          { required: true, trigger: 'change', message: this.$t('deviceType.selectProductPange') }
        ],
        signatureFileName: [{ required: true, trigger: 'change', message: this.$t('placeholder') }],
        name: [{ required: true, message: this.$t('deviceType.pleaseEnterModel') }]
      },
      options: [
        {
          label: this.$t('adminHomePage.pos'),
          value: 1
        },
        {
          label: this.$t('adminHomePage.selfHelp'),
          value: 2
        },
        {
          label: this.$t('adminHomePage.commercialDisplay'),
          value: 3
        }
      ],
      signList: []
    }
  },

  created() {
    this.getSign()
    if (!this.isCreated) {
      this.imageUrl = this.currentItem.imagePath
      this.form.name = this.currentItem.name
      this.form.series = this.currentItem.series
      this.form.signatureFileName = this.currentItem.signatureFileId || ''
      this.form.remark = this.currentItem?.remark || ''
    }
  },

  methods: {
    async getSign() {
      try {
        this.loading = true
        let { result } = await this.$api.deviceManagement.getSignList()
        this.signList = result || []
      } finally {
        this.loading = false
      }
    },
    onchange(file) {
      this.imageUrl = URL.createObjectURL(file.raw)
      this.form.file = file.raw
    },
    submitForm(data) {
      this.$refs.form.validate(async valid => {
        if (valid) {
          this.loading = true
          let params = new FormData()
          params.append('imagePath', this.form.file)
          params.append('name', this.form.name)
          params.append('signatureFileName', this.form.signatureFileName)
          params.append('remark', this.form.remark)
          params.append('series', +this.form.series)

          if (!this.isCreated) {
            params.append('id', this.currentItem.id)
          }
          try {
            this.isCreated
              ? await this.$api.deviceManagement.modelAdd(params)
              : await this.$api.deviceManagement.modelUpdate(params)

            this.$message.success(this.isCreated ? this.$t('newSuccess') : this.$t('editSuccess'))
            this.$emit('getData')
            this.show = false
          } catch (e) {
            console.log(e)
          } finally {
            this.loading = false
          }
        }
      })
    }
  }
}
</script>

<style lang="scss" scoped>
.add-deviceType {
  .el-select {
    width: 100%;
  }

  .el-upload-list__item-name {
    cursor: default;
    &:hover,
    &:focus {
      color: inherit !important;
    }
  }

  ::v-deep .avatar-uploader .el-upload {
    border: 1px dashed #d9d9d9;
    border-radius: 6px;
    cursor: pointer;
    position: relative;
    overflow: hidden;
  }
  .avatar-uploader .el-upload:hover {
    border-color: #ef2b23;
  }
  .avatar-uploader-icon {
    font-size: 28px;
    color: #8c939d;
    width: 178px;
    height: 178px;
    line-height: 178px;
    text-align: center;
  }
  .avatar {
    width: 178px;
    height: 178px;
    display: block;
  }
}
</style>
