<template>
  <el-dialog
    v-elDragDialog
    width="1000px"
    :title="$t('newadd')"
    :close-on-click-modal="false"
    :visible.sync="show"
  >
    <div>
      <el-form inline>
        <el-form-item :label="$t('deviceList.sn')">
          <el-input
            :placeholder="$t('deviceList.sn')"
            v-model.trim="params.sn"
            @keyup.enter.native="replaceData"
            style="width: 260px;"
            clearable
          />
        </el-form-item>
        <el-form-item>
          <el-button type="primary" plain @click="replaceData">{{ $t('query') }}</el-button>
        </el-form-item>
      </el-form>
      <el-table
        ref="table"
        type="selection"
        row-key="id"
        @selection-change="val => (multipleSelection = val.map(item => item.sn))"
        :data="tableData"
        border
        v-loading="loading"
        height="40vh"
      >
        <el-table-column type="selection" :reserve-selection="true" width="55" />

        <el-table-column
          min-width="200"
          prop="sn"
          show-overflow-tooltip
          :label="$t('deviceList.sn')"
        >
        </el-table-column>

        <el-table-column
          show-overflow-tooltip
          prop="modelName"
          width="150"
          :label="$t('deviceList.deviceType')"
        />
        <el-table-column
          show-overflow-tooltip
          prop="groupName"
          width="150"
          :label="$t('deviceList.deviceGroup')"
        />
        <el-table-column
          show-overflow-tooltip
          prop="spName"
          :label="$t('deviceList.affiliatedStore')"
        />
        <el-table-column show-overflow-tooltip prop="store" :label="$t('organManagement.remark')" />

        <el-table-column
          show-overflow-tooltip
          prop="firmwareVersion"
          width="150"
          :label="$t('deviceList.firmwareVersion')"
        />
      </el-table>
      <pagination
        :total="total"
        :page-size.sync="params.pageSize"
        :page.sync="params.currentPage"
        @change="getData"
      />
    </div>
    <span slot="footer" class="dialog-footer">
      <el-button @click="show = false">{{ $t('cancel') }}</el-button>
      <el-button type="primary" @click="submitEvent" v-loading="submitLoading">{{
        $t('submit')
      }}</el-button>
    </span>
  </el-dialog>
</template>

<script>
import dialogCommonParams from '@/mixins/dialogCommonParams'
import watchParamsGetData from '@/mixins/watchParamsGetData'
export default {
  mixins: [dialogCommonParams, watchParamsGetData],
  components: {},
  data() {
    return {
      multipleSelection: [],
      submitLoading: false
    }
  },

  created() {},

  methods: {
    async getData(loading = true) {
      loading ? (this.loading = true) : ''
      try {
        const res = await this.$api.deviceManagement.deviceList({ ...this.params, otaStatus: 0 })

        if (res.result) {
          this.tableData = (res.result.list || []).map(item => {
            if (item.groups && item.groups.length) {
              item.groupName = item.groups[0] && item.groups[0].name
            }
            return item
          })
          this.total = res.result.total
        } else {
          this.tableData = []
          this.total = 0
        }
      } catch (e) {
        console.log(e)
      } finally {
        this.loading = false
      }
    },
    async submitEvent() {
      const { multipleSelection } = this
      if (!multipleSelection.length) {
        this.$message.warning(this.$t('leastOne'))
        return
      }
      try {
        this.submitLoading = true
        const res = await this.$api.deviceManagement.updateOtaStatus({
          type: 1,
          arr: multipleSelection
        })
        this.$message.success(this.$t('newSuccess'))
        this.$emit('getData')
        this.show = false
      } catch (e) {
        console.log(e)
      } finally {
        this.submitLoading = false
      }
    }
  }
}
</script>

<style lang="scss" scoped></style>
