<template>
  <page>
    <div slot="headerLeft">
      <el-form inline>
        <el-form-item :label="$t('deviceList.sn')">
          <el-input
            :placeholder="$t('deviceList.sn')"
            v-model.trim="params.sn"
            @keyup.enter.native="replaceData"
            style="width: 260px;"
            clearable
          />
        </el-form-item>
        <el-form-item>
          <el-button type="primary" plain @click="replaceData">{{ $t('query') }}</el-button>
          <el-button
            type="danger"
            plain
            @click="delHandler(true)"
            v-if="btnShow && btnShow.delete"
            >{{ $t('delete') }}</el-button
          >
          <el-button
            v-if="btnShow && btnShow.add"
            type="primary"
            @click="
              visible = true
              currentItem = null
            "
            >{{ $t('newadd') }}</el-button
          >
        </el-form-item>
      </el-form>
    </div>

    <div class="table-wrap">
      <el-table
        ref="table"
        row-key="id"
        @selection-change="val => (multipleSelection = val.map(item => item.sn))"
        :data="tableData"
        border
        v-loading="loading"
        height="100%"
      >
        <el-table-column
          v-if="btnShow && btnShow.delete"
          type="selection"
          :reserve-selection="true"
          width="55"
        />

        <el-table-column
          min-width="200"
          prop="sn"
          show-overflow-tooltip
          :label="$t('deviceList.sn')"
        >
        </el-table-column>

        <el-table-column
          show-overflow-tooltip
          prop="modelName"
          width="150"
          :label="$t('deviceList.deviceType')"
        />
        <el-table-column
          show-overflow-tooltip
          prop="groupName"
          width="150"
          :label="$t('deviceList.deviceGroup')"
        />
        <el-table-column
          show-overflow-tooltip
          prop="spName"
          :label="$t('deviceList.affiliatedStore')"
        />
        <el-table-column show-overflow-tooltip prop="store" :label="$t('organManagement.remark')" />

        <el-table-column
          show-overflow-tooltip
          prop="firmwareVersion"
          :label="$t('deviceList.firmwareVersion')"
        />

        <el-table-column width="80" :label="$t('operation')">
          <template slot-scope="{ row }">
            <div class="operateRow">
              <el-button
                v-if="btnShow && btnShow.delete"
                type="text"
                @click="delHandler(false, row.sn)"
                >{{ $t('delete') }}</el-button
              >
            </div>
          </template>
        </el-table-column>
      </el-table>
    </div>
    <pagination
      slot="pagination"
      :total="total"
      :page-size.sync="params.pageSize"
      :page.sync="params.currentPage"
      @change="getData"
    />
    <AddModal
      @getData="replaceData"
      :currentItem="currentItem"
      :visible.sync="visible"
      v-if="visible"
    />
  </page>
</template>

<script>
import watchParamsGetData from '@/mixins/watchParamsGetData'
import AddModal from './components/AddModal'

export default {
  mixins: [watchParamsGetData],
  components: {
    AddModal
  },
  data() {
    return {
      loading: false,
      visible: false,
      currentItem: {},
      tableData: [],
      multipleSelection: []
    }
  },
  created() {},
  methods: {
    async getData(loading = true) {
      loading ? (this.loading = true) : ''
      try {
        const data = { ...this.params, otaStatus: 1 }
        this.$refs.table?.clearSelection()
        const res = await this.$api.deviceManagement.deviceList(data)

        if (res.result) {
          this.tableData = (res.result.list || []).map(item => {
            if (item.groups && item.groups.length) {
              item.groupName = item.groups[0] && item.groups[0].name
            }
            return item
          })
          this.total = res.result.total
        } else {
          this.tableData = []
          this.total = 0
        }
      } catch (e) {
        console.log(e)
      } finally {
        this.loading = false
      }
    },
    async delHandler(delMore, sn) {
      if (delMore && !this.multipleSelection.length) {
        this.$message.warning(this.$t('leastOne'))
        return
      }

      await this.$confirm(
        delMore ? this.$t('deviceList.isDeleteDevice') : this.$t('deviceList.isDeleteThisDevice'),
        this.$t('tips'),
        {
          confirmButtonText: this.$t('confirm'),
          cancelButtonText: this.$t('cancel')
        }
      )
      this.loading = true
      try {
        await this.$api.deviceManagement.updateOtaStatus({
          type: 0,
          arr: delMore ? this.multipleSelection : [sn]
        })
        this.$message.success(this.$t('deleteSuccess'))
        this.$refs.table && this.$refs.table.clearSelection()
        this.getData()
      } catch (e) {
        console.log(e)
      } finally {
        this.loading = false
      }
    }
  }
}
</script>

<style scoped lang="scss">
.el-form-item {
  margin-bottom: 4px;
}
</style>
